import { AppImages } from 'components';
import * as AppPics from 'assets/images';

function SideBar() {
  return (
    <header>
      <div className="logoWrap">
        <span className="logoWrap__logo">
          <AppImages alt="LeadlyApp Logo" src={AppPics.LeadlyAppLogo} />
        </span>
      </div>
      <ul className="menuWrap">
        <li>
          <a href="#console" className="menuWrap__menu">
            <span className="menuWrap__menuName">Menu</span>
          </a>
        </li>
      </ul>
    </header>
  );
}

export default SideBar;
