import SideBar from './SideBar';
import { withRouter } from 'react-router-dom';

function AdminLayout({ children }) {
  return (
    <div className="container-fluid app_wrap">
      <SideBar />
      <main>{children}</main>
    </div>
  );
}

export default withRouter(AdminLayout);
