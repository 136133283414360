import { Route, Switch } from 'react-router-dom';
import AdminLayout from 'pages/Layouts/Admin/AdminLayout';
import SharePreviewSection from 'pages/share-content-preview/manage-contents/PreviewSection';
import { Redirect } from 'react-router-dom';

function AuthenticatedAdminRoute({ children, customProps = {}, ...rest }) {
  return (
    <Route {...rest}>
      <AdminLayout customProps={customProps}>{children}</AdminLayout>
    </Route>
  );
}

function AppRoutes() {
  return (
    <Switch>
      <AuthenticatedAdminRoute
        path="/classes/:classID/courses/:courseID/chapters/:chapterID/topics/:topicID/contentPreview"
        exact
        customProps={{ sideMenu: { showMenu: true, showContentsSubMenu: true, isDiscover: true } }}
      >
        <SharePreviewSection />
      </AuthenticatedAdminRoute>
      <Route>
        <Redirect to="/classes/62a747dd9f35fdb84d6dd18d/courses/65eeb954c0173821b9e0ca8c/chapters/65fe3c17943c0ae041bdd2c4/topics/65fe3ef1943c0ae041bdd847/contentPreview?lessonType=notes&subtopic=65fe3ef1943c0ae041bdd852" />
      </Route>
    </Switch>
  );
}

export default AppRoutes;
