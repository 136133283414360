import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

function useQueryParams() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function QueryParamsAsString() {
  const __search = new URLSearchParams(window.location.search);

  let queryString = ``;
  for (const [key, value] of __search.entries()) {
    queryString += key + '=' + value + '&';
  }

  queryString = queryString.slice(0, -1);
  queryString = queryString ? '?' + queryString : '';

  return queryString;
}

export { QueryParamsAsString };
export default useQueryParams;
