import { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useQueryParams from 'hooks/useQueryParams';
import useShareableTopics from 'hooks/API/GET/useShareableTopics';
import DOMPurify from 'dompurify';
import { highlightRange, removeHighlights } from 'utilities/Highlighter';
import useHighlighterData from 'hooks/API/GET/useHighlighterData';
import { serverFetch } from 'utilities/fetch';
import ToolTip from 'components/toolTip';

function SharePreviewSection() {
  const [blocks, setBlocks] = useState([]);
  const [previousBlocks, setPreviousBlocks] = useState([]);
  const [selectedText, setSelectedText] = useState('');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [selectedRange, setSelectedRange] = useState();
  const [currentActiveHighlight, setCurrentActiveHighlight] = useState();
  const [highlighterData, setHighlighterData] = useState();
  const [currentActiveId, setCurrentActiveId] = useState();

  useEffect(() => {
    if (blocks) {
      const node = document.querySelector('#MATH-CONTENT-AREA');
      window.MathJax.typesetClear([node]);
      window.MathJax.typesetPromise([node]).then(() => {});
    }
  }, [blocks]);
  const ref = useRef();

  const { classID, courseID, chapterID, topicID } = useParams();
  const query = useQueryParams();
  const subtopicID = query.get('subtopic');
  const lessonType = query.get('lessonType');

  const __params = { classID, courseID, chapterID, topicID };
  const { data } = useShareableTopics({
    ...__params,
    enabled: true,
    configs: { params: { subtopicID, commentPage: true } },
  });

  const { data: highlighterDataItems } = useHighlighterData(subtopicID);

  useEffect(() => {
    if (data?.data?.notes?.[0]?.blocks) {
      setBlocks(
        data?.data?.notes[0]?.blocks?.map((x) => ({
          ...x,
          id: x._id,
          value: x.value.replace(/&lt;/g, '<').replace(/&gt;/g, '>'),
        }))
      );
    }
    if (data?.data?.notes?.[0]?.previousBlock)
      setPreviousBlocks(
        data?.data?.notes[0]?.previousBlock?.map((x) => ({
          ...x,
          id: x._id,
          value: x.value.replace(/&lt;/g, '<').replace(/&gt;/g, '>'),
        }))
      );
  }, [data?.data, lessonType]);

  useEffect(() => {
    if (data?.data && highlighterDataItems && highlighterDataItems.length > 0) {
      setTimeout(() => {
        highlighterDataItems.forEach((item) => {
          createHighlighter(item.highlighterData, item._id);
        });
      }, 2000);
    }
  }, [data?.data, highlighterDataItems]);

  const handleSelection = async () => {
    setTooltipVisible(false);
    var selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
      let range = selection.getRangeAt(0);
      let startNode = range.startContainer;
      let endNode = range.endContainer;

      if (startNode.nodeType == 3) {
        var startIsText = true;
        var startFlag = startNode.parentNode;
        startNode = startNode.nodeValue;
      } else {
        var startIsText = false;
        var startFlag = startNode;
      }
      if (endNode.nodeType == 3) {
        var endIsText = true;
        var endFlag = endNode.parentNode;
        endNode = endNode.nodeValue;
      } else {
        var endIsText = false;
        var endFlag = endNode;
      }

      let startOffset = range.startOffset;
      let endOffset = range.endOffset;

      let startTagName = startFlag.nodeName;
      let startHTML = startFlag.innerHTML;

      let endTagName = endFlag.nodeName;
      let endHTML = endFlag.innerHTML;

      //you can store this in database and use it
      let rInfo = {
        startNode: startNode,
        startOffset: startOffset,
        startIsText: startIsText,
        startTagName: startTagName,
        startHTML: startHTML,

        endNode: endNode,
        endOffset: endOffset,
        endIsText: endIsText,
        endTagName: endTagName,
        endHTML: endHTML,
      };
      setHighlighterData(rInfo);
      setSelectedRange(range);
      const rect = range.getBoundingClientRect();
      setSelectedText(selection.toString());
      setTooltipPosition({ x: rect.x, y: rect.y + window.scrollY - 42 });
      setTooltipVisible(true);
    }
  };

  function findEle(tagName, innerHTML) {
    let list = document.getElementsByTagName(tagName);
    for (let i = 0; i < list.length; i++) {
      if (list[i].innerHTML == innerHTML) {
        return list[i];
      }
    }
  }

  function showNodesData(startNode, startIsText, startOffset, endNode, endIsText, endOffset, startParent, endParent, id) {
    var start, end;
    if (startIsText) {
      let childs = startParent.childNodes;
      for (let i = 0; i < childs.length; i++) {
        if (childs[i].nodeType == 3 && childs[i].nodeValue == startNode) start = childs[i];
      }
    } else {
      start = startNode;
    }
    if (endIsText) {
      let childs = endParent.childNodes;
      for (let i = 0; i < childs.length; i++) {
        if (childs[i].nodeType == 3 && childs[i].nodeValue == endNode) end = childs[i];
      }
    } else {
      end = endNode;
    }
    let range = document.createRange();
    range.setStart(start, startOffset);
    range.setEnd(end, endOffset);
    const rect = range.getBoundingClientRect();
    const positionTooltip = { x: rect.x, y: rect.y + window.scrollY - 42 };
    highlightRange(range, positionTooltip, id, handleCallBackAgain);
  }

  function createHighlighter(obj, id) {
    let startParent = findEle(obj.startTagName, obj.startHTML);
    let endParent = findEle(obj.endTagName, obj.endHTML);
    showNodesData(
      obj.startNode,
      obj.startIsText,
      obj.startOffset,
      obj.endNode,
      obj.endIsText,
      obj.endOffset,
      startParent,
      endParent,
      id
    );
  }

  const handleHighlight = async () => {
    var selection = window.getSelection();
    selection.removeAllRanges();
    const postData = {
      highlighterData: highlighterData,
      exactSelectedText: selectedText,
      articleId: subtopicID,
    };
    const response = await serverFetch.post('/api/v1/highlighter', postData);
    highlightRange(selectedRange, tooltipPosition, response.id, handleCallBackAgain);
    setTooltipVisible(false);
  };

  const handleCallBackAgain = (highlightEl, positionForTooltip, id) => {
    setTooltipPosition(positionForTooltip);
    setCurrentActiveHighlight(highlightEl);
    setCurrentActiveId(id);
    setTooltipVisible(true);
  };

  const removeHighlightsData = () => {
    removeHighlights(currentActiveHighlight);
    setTooltipVisible(false);
    removeHighlighterData();
  };

  const removeHighlighterData = async () => {
    const response = await serverFetch.delete(`/api/v1/highlighter/?id=${currentActiveId}`);
    return response.success;
  };

  const __linkParams = { lessonType: 'notes' };
  if (subtopicID) __linkParams.subtopic = subtopicID;

  return (
    <>
      <div className="mainWraper">
        <div className="tab">
          <ul className="tab__menu">
            <li className={'active'}>Preview</li>
          </ul>
          {/* Compare Button */}

          <div className="tab__content ___adjust___tab___padding">
            <div
              className="row Texteditorsection relative __noMin__hgt __noborder __selection__area__"
              id="MATH-CONTENT-AREA"
              ref={ref}
              onMouseUp={handleSelection}
            >
              {blocks.map((block) => {
                return (
                  <Fragment key={block.id}>
                    <div className="orginal-content">
                      {['text', 'problem', 'tip', 'box'].includes(block.currentBlock) && (
                        <div className="col-12 add_comment___area">
                          <div
                            data-block-id={block._id}
                            className="__AppContentBlocks__"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(block.value),
                            }}
                          />
                        </div>
                      )}

                      {block.currentBlock === 'image' && (
                        <div className="__image_tool ___blockTools blue__light__border flex-p flex-column jc-center ai-center">
                          <div className="ratio ratio-21x9">
                            <img src={block.assetUrl} alt="presentational block content" />
                          </div>
                        </div>
                      )}
                      {block.currentBlock === 'video' && (
                        <div className="__video_tool ___blockTools blue__light__border flex-p flex-column jc-center ai-center">
                          <div className="ratio ratio-21x9">
                            <video width="320" height="240" controls>
                              <source src={block.assetUrl} />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        </div>
                      )}
                      {block.currentBlock === 'audio' && (
                        <div className="__audio_tool ___blockTools blue__light__border flex-p flex-column jc-center ai-center">
                          <div className="ratio ratio-21x9">
                            <audio controls>
                              <source src={block.assetUrl} />
                              Your browser does not support the audio tag.
                            </audio>
                          </div>
                        </div>
                      )}

                      {block.currentBlock === 'equation' && (
                        <div className="__file_tool ___blockTools blue__light__border flex-p flex-column jc-center ai-center">
                          <div className="__equation_tool col-12">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(block.value),
                              }}
                            ></div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Fragment>
                );
              })}
              {previousBlocks.map((block) => {
                return (
                  <Fragment key={block.id}>
                    <div className="previous-content">
                      {['text', 'problem', 'tip', 'box'].includes(block.currentBlock) && (
                        <div className="col-12 add_comment___area">
                          <div
                            data-block-id={block._id}
                            className="__AppContentBlocks__"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(block.value),
                            }}
                          />
                        </div>
                      )}
                      {block.currentBlock === 'image' && (
                        <div className="__image_tool ___blockTools blue__light__border flex-p flex-column jc-center ai-center">
                          <div className="ratio ratio-21x9">
                            <img src={block.assetUrl} alt="presentational block content" />
                          </div>
                        </div>
                      )}
                      {block.currentBlock === 'video' && (
                        <div className="__video_tool ___blockTools blue__light__border flex-p flex-column jc-center ai-center">
                          <div className="ratio ratio-21x9">
                            <video width="320" height="240" controls>
                              <source src={block.assetUrl} />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        </div>
                      )}
                      {block.currentBlock === 'audio' && (
                        <div className="__audio_tool ___blockTools blue__light__border flex-p flex-column jc-center ai-center">
                          <div className="ratio ratio-21x9">
                            <audio controls>
                              <source src={block.assetUrl} />
                              Your browser does not support the audio tag.
                            </audio>
                          </div>
                        </div>
                      )}

                      {block.currentBlock === 'equation' && (
                        <div className="__equation_tool col-12">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(block.value),
                            }}
                          ></div>
                        </div>
                      )}
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {tooltipVisible && (
        <ToolTip
          handleHighlight={handleHighlight}
          removeHighlightsData={removeHighlightsData}
          tooltipPosition={tooltipPosition}
        />
      )}
    </>
  );
}

const _initialState = {
  open: false,
  modal_id: null,
  dataBlockId: null,
  selectedCommentText: '',
};

export default SharePreviewSection;
