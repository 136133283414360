export function highlightRange(
    range,tooltipPosition,id,onClickCallback
) {
    const textNodes = wholeTextNodesInRange(range);

    // Group text nodes into spans of adjacent nodes. If a group of text nodes are
    // adjacent, we only need to create one highlight element for the group.
    let textNodeSpans = [];
    let prevNode = null;
    let currentSpan = null;

    textNodes.forEach(node => {
        if (prevNode && prevNode.nextSibling === node) {
            currentSpan.push(node);
        } else {
            currentSpan = [node];
            textNodeSpans.push(currentSpan);
        }
        prevNode = node;
    });


    // Filter out text node spans that consist only of white space. This avoids
    // inserting highlight elements in places that can only contain a restricted
    // subset of nodes such as table rows and lists.
    const whitespace = /^\s*$/;
    textNodeSpans = textNodeSpans.filter(span =>
        // Check for at least one text node with non-space content.
        span.some(node => !whitespace.test(node.data)),
    );
    // console.log(textNodeSpans,'textNodeSpans==>>')
    // Wrap each text node span with a `<hypothesis-highlight>` element.
    const highlights = [];
    textNodeSpans.forEach(nodes => {
        // A custom element name is used here rather than `<span>` to reduce the
        // likelihood of highlights being hidden by page styling.

        const highlightEl = document.createElement('leadlyapp');
        highlightEl.style.backgroundColor = 'yellow';
        highlightEl.style.cursor = 'pointer';

        const parent = nodes[0].parentNode;
        console.log(parent,'parent',nodes[0]);
        parent.replaceChild(highlightEl, nodes[0]);
        nodes.forEach(node => highlightEl.appendChild(node));
        // highlightEl.addEventListener('click', () => onClickCallback(highlightEl,tooltipPosition));
        highlights.push(highlightEl);
    });
    document.addEventListener('click', (event) => {
        const target = event.target;
        if (highlights.includes(target)) {
            onClickCallback(highlights, tooltipPosition,id);
        }
    });
    return highlights;
}

function wholeTextNodesInRange(range) {
    if (range.collapsed) {
        // Exit early for an empty range to avoid an edge case that breaks the algorithm
        // below. Splitting a text node at the start of an empty range can leave the
        // range ending in the left part rather than the right part.
        return [];
    }

    let root = range.commonAncestorContainer;
    // console.log(root,"root")
    if (root && root.nodeType !== Node.ELEMENT_NODE) {
        // If the common ancestor is not an element, set it to the parent element to
        // ensure that the loop below visits any text nodes generated by splitting
        // the common ancestor.
        //
        // Note that `parentElement` may be `null`.
        root = root.parentElement;
    }
    if (!root) {
        // If there is no root element then we won't be able to insert highlights,
        // so exit here.
        return [];
    }

    const textNodes = [];
    const nodeIter = root.ownerDocument.createNodeIterator(
        root,
        NodeFilter.SHOW_TEXT, // Only return `Text` nodes.
    );
    // console.log(nodeIter,'nodeIter');

    let node;
    while ((node = nodeIter.nextNode())) {
        if (!isNodeInRange(range, node)) {
            continue;
        }
        const text = node;

        if (text === range.startContainer && range.startOffset > 0) {
            // Split `text` where the range starts. The split will create a new `Text`
            // node which will be in the range and will be visited in the next loop iteration.
            text.splitText(range.startOffset);
            continue;
        }

        if (text === range.endContainer && range.endOffset < text.data.length) {
            // Split `text` where the range ends, leaving it as the part in the range.
            text.splitText(range.endOffset);
        }

        textNodes.push(text);
    }
    // console.log(textNodes,'textNodes textNodes');


    return textNodes;
}

function isNodeInRange(range, node) {
    try {
        const length = node.nodeValue?.length ?? node.childNodes.length;
        return (
            // Check start of node is before end of range.
            range.comparePoint(node, 0) <= 0 &&
            // Check end of node is after start of range.
            range.comparePoint(node, length) >= 0
        );
    } catch (e) {
        // `comparePoint` may fail if the `range` and `node` do not share a common
        // ancestor or `node` is a doctype.
        return false;
    }
}

/**
* Remove highlights from a range previously highlighted with `highlightRange`.
*/
export function removeHighlights(highlights) {
    setHighlightsFocused(highlights, false);
    for (const h of highlights) {
        if (h.parentNode) {
            const children = Array.from(h.childNodes);
            replaceWith(h, children);
        }
    }
}

/**
* Replace a child `node` with `replacements`.
*
* nb. This is like `ChildNode.replaceWith` but it works in older browsers.
*/
function replaceWith(node, replacements) {
    const parent = node.parentNode;
    replacements.forEach(r => parent.insertBefore(r, node));
    node.remove();
}

export function setHighlightsFocused(
    highlights,
    focused,
) {
    highlights.forEach(h => {
        h.classList.toggle('hypothesis-highlight-focused', focused);
    });
}