import axios from 'axios';

function delay(time, value) {
  return new Promise((resolve) => setTimeout(resolve.bind(null, value), time));
}

const publicFetch = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const serverFetch = axios.create({
  baseURL: '',
});

export { serverFetch, publicFetch, delay };
