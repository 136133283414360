import * as AppPics from 'assets/images';

function ToolTip(props) {
  const { handleHighlight, removeHighlightsData, tooltipPosition } = props;
  return (
    <div className="tooltip" style={{ top: tooltipPosition.y, left: tooltipPosition.x }}>
      <div onClick={handleHighlight}>
        <img className="toolTipIcons" src={AppPics.addComment} alt="addComment icon"/>
      </div>
      <div onClick={removeHighlightsData}>
        <img className="toolTipIcons" src={AppPics.deSelect} alt="deSelect icon"/>
      </div>
      <div>
        <img className="toolTipIcons" src={AppPics.editComment} alt="editComment icon"/>
      </div>
      <div>
        <img className="toolTipIcons" src={AppPics.deleteComment} alt="deleteComment icon"/>
      </div>
      <div>
        <img className="toolTipIcons" src={AppPics.snapShot} alt="snapShot icon"/>
      </div>
    </div>
  );
}

export default ToolTip;
