import { useQuery } from 'react-query';
import { serverFetch } from 'utilities/fetch';

function useHighlighterData(articleId) {
  async function getData() {
    try {
      const response = await serverFetch.get(`/api/v1/highlighter/?articleId=${articleId}`);
      return response.data.data;
    } catch (error) {
      throw new Error('Failed to fetch highlighter data');
    }
  }
  return useQuery(['highlighter-data', articleId], getData, { enabled: true });
}

export default useHighlighterData;
