import { useQuery } from 'react-query';
import { publicFetch } from 'utilities/fetch';

function useShareableTopics(data = {}) {
  const { enabled = false, configs = {} } = data;

  async function getData() {
    return publicFetch.get(`/v1/admin/shareableContents`, configs).then((res) => {
      return res.data;
    });
  }

  return useQuery('shareable-details', getData, { enabled });
}

export default useShareableTopics;
